import { Nation } from '@/types'
import { reactive, toRefs } from 'vue'

export const store = reactive<{
  code: Nation
  showList: number[]
  urlParmas: Record<string, any>
}>({
  code: Nation.BR,
  showList: [],
  urlParmas: {},
})

export function useStore() {
  return toRefs(store)
}
