// @ts-nocheck
/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.19.4
 * source: ../../../../../proto/portal/activity-api/survey.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./ActivityApiActivityCommonProto";
import * as pb_1 from "google-protobuf";
export namespace ActivityApiSurveyProto {
    /*
     *  /act/survey/get
     */
    export class GetSurveyReq extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(
            data?:
                | any[]
                | {
                      header?: dependency_1.ActivityApiActivityCommonProto.ReqHeader;
                      act_id?: string;
                  },
        ) {
            super();
            pb_1.Message.initialize(
                this,
                Array.isArray(data) ? data : [],
                0,
                -1,
                [],
                this.#one_of_decls,
            );
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
                if ("act_id" in data && data.act_id != undefined) {
                    this.act_id = data.act_id;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(
                this,
                dependency_1.ActivityApiActivityCommonProto.ReqHeader,
                1,
            ) as dependency_1.ActivityApiActivityCommonProto.ReqHeader;
        }
        set header(value: dependency_1.ActivityApiActivityCommonProto.ReqHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get act_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set act_id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            header?: ReturnType<
                typeof dependency_1.ActivityApiActivityCommonProto.ReqHeader.prototype.toObject
            >;
            act_id?: string;
        }): GetSurveyReq {
            const message = new GetSurveyReq({});
            if (data.header != null) {
                message.header = dependency_1.ActivityApiActivityCommonProto.ReqHeader.fromObject(
                    data.header,
                );
            }
            if (data.act_id != null) {
                message.act_id = data.act_id;
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<
                    typeof dependency_1.ActivityApiActivityCommonProto.ReqHeader.prototype.toObject
                >;
                act_id?: string;
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            if (this.act_id != null) {
                data.act_id = this.act_id;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (this.act_id.length) writer.writeString(2, this.act_id);
            if (!w) return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSurveyReq {
            const reader =
                    bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                message = new GetSurveyReq();
            while (reader.nextField()) {
                if (reader.isEndGroup()) break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(
                            message.header,
                            () =>
                                (message.header =
                                    dependency_1.ActivityApiActivityCommonProto.ReqHeader.deserialize(
                                        reader,
                                    )),
                        );
                        break;
                    case 2:
                        message.act_id = reader.readString();
                        break;
                    default:
                        reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetSurveyReq {
            return GetSurveyReq.deserialize(bytes);
        }
    }
    export class SurveyConf extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(
            data?:
                | any[]
                | {
                      id?: string;
                      survey_id?: number;
                      survey_urls?: string[];
                      survey?: string;
                  },
        ) {
            super();
            pb_1.Message.initialize(
                this,
                Array.isArray(data) ? data : [],
                0,
                -1,
                [3],
                this.#one_of_decls,
            );
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("survey_id" in data && data.survey_id != undefined) {
                    this.survey_id = data.survey_id;
                }
                if ("survey_urls" in data && data.survey_urls != undefined) {
                    this.survey_urls = data.survey_urls;
                }
                if ("survey" in data && data.survey != undefined) {
                    this.survey = data.survey;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get survey_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set survey_id(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get survey_urls() {
            return pb_1.Message.getFieldWithDefault(this, 3, []) as string[];
        }
        set survey_urls(value: string[]) {
            pb_1.Message.setField(this, 3, value);
        }
        get survey() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set survey(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            id?: string;
            survey_id?: number;
            survey_urls?: string[];
            survey?: string;
        }): SurveyConf {
            const message = new SurveyConf({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.survey_id != null) {
                message.survey_id = data.survey_id;
            }
            if (data.survey_urls != null) {
                message.survey_urls = data.survey_urls;
            }
            if (data.survey != null) {
                message.survey = data.survey;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                survey_id?: number;
                survey_urls?: string[];
                survey?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.survey_id != null) {
                data.survey_id = this.survey_id;
            }
            if (this.survey_urls != null) {
                data.survey_urls = this.survey_urls;
            }
            if (this.survey != null) {
                data.survey = this.survey;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length) writer.writeString(1, this.id);
            if (this.survey_id != 0) writer.writeInt64(2, this.survey_id);
            if (this.survey_urls.length) writer.writeRepeatedString(3, this.survey_urls);
            if (this.survey.length) writer.writeString(4, this.survey);
            if (!w) return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SurveyConf {
            const reader =
                    bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                message = new SurveyConf();
            while (reader.nextField()) {
                if (reader.isEndGroup()) break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.survey_id = reader.readInt64();
                        break;
                    case 3:
                        pb_1.Message.addToRepeatedField(message, 3, reader.readString());
                        break;
                    case 4:
                        message.survey = reader.readString();
                        break;
                    default:
                        reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SurveyConf {
            return SurveyConf.deserialize(bytes);
        }
    }
    export class GetSurveyResp extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(
            data?:
                | any[]
                | {
                      header?: dependency_1.ActivityApiActivityCommonProto.RespHeader;
                      data?: GetSurveyResp.RespData;
                  },
        ) {
            super();
            pb_1.Message.initialize(
                this,
                Array.isArray(data) ? data : [],
                0,
                -1,
                [],
                this.#one_of_decls,
            );
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
                if ("data" in data && data.data != undefined) {
                    this.data = data.data;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(
                this,
                dependency_1.ActivityApiActivityCommonProto.RespHeader,
                1,
            ) as dependency_1.ActivityApiActivityCommonProto.RespHeader;
        }
        set header(value: dependency_1.ActivityApiActivityCommonProto.RespHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get data() {
            return pb_1.Message.getWrapperField(
                this,
                GetSurveyResp.RespData,
                2,
            ) as GetSurveyResp.RespData;
        }
        set data(value: GetSurveyResp.RespData) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_data() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            header?: ReturnType<
                typeof dependency_1.ActivityApiActivityCommonProto.RespHeader.prototype.toObject
            >;
            data?: ReturnType<typeof GetSurveyResp.RespData.prototype.toObject>;
        }): GetSurveyResp {
            const message = new GetSurveyResp({});
            if (data.header != null) {
                message.header = dependency_1.ActivityApiActivityCommonProto.RespHeader.fromObject(
                    data.header,
                );
            }
            if (data.data != null) {
                message.data = GetSurveyResp.RespData.fromObject(data.data);
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<
                    typeof dependency_1.ActivityApiActivityCommonProto.RespHeader.prototype.toObject
                >;
                data?: ReturnType<typeof GetSurveyResp.RespData.prototype.toObject>;
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            if (this.data != null) {
                data.data = this.data.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (this.has_data) writer.writeMessage(2, this.data, () => this.data.serialize(writer));
            if (!w) return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetSurveyResp {
            const reader =
                    bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                message = new GetSurveyResp();
            while (reader.nextField()) {
                if (reader.isEndGroup()) break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(
                            message.header,
                            () =>
                                (message.header =
                                    dependency_1.ActivityApiActivityCommonProto.RespHeader.deserialize(
                                        reader,
                                    )),
                        );
                        break;
                    case 2:
                        reader.readMessage(
                            message.data,
                            () => (message.data = GetSurveyResp.RespData.deserialize(reader)),
                        );
                        break;
                    default:
                        reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): GetSurveyResp {
            return GetSurveyResp.deserialize(bytes);
        }
    }
    export namespace GetSurveyResp {
        export class RespData extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(
                data?:
                    | any[]
                    | {
                          conf?: SurveyConf;
                          left_ts?: number;
                          prop_id?: number;
                          min_val?: number;
                          max_val?: number;
                      },
            ) {
                super();
                pb_1.Message.initialize(
                    this,
                    Array.isArray(data) ? data : [],
                    0,
                    -1,
                    [],
                    this.#one_of_decls,
                );
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("conf" in data && data.conf != undefined) {
                        this.conf = data.conf;
                    }
                    if ("left_ts" in data && data.left_ts != undefined) {
                        this.left_ts = data.left_ts;
                    }
                    if ("prop_id" in data && data.prop_id != undefined) {
                        this.prop_id = data.prop_id;
                    }
                    if ("min_val" in data && data.min_val != undefined) {
                        this.min_val = data.min_val;
                    }
                    if ("max_val" in data && data.max_val != undefined) {
                        this.max_val = data.max_val;
                    }
                }
            }
            get conf() {
                return pb_1.Message.getWrapperField(this, SurveyConf, 1) as SurveyConf;
            }
            set conf(value: SurveyConf) {
                pb_1.Message.setWrapperField(this, 1, value);
            }
            get has_conf() {
                return pb_1.Message.getField(this, 1) != null;
            }
            get left_ts() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set left_ts(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get prop_id() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set prop_id(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get min_val() {
                return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
            }
            set min_val(value: number) {
                pb_1.Message.setField(this, 4, value);
            }
            get max_val() {
                return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
            }
            set max_val(value: number) {
                pb_1.Message.setField(this, 5, value);
            }
            static fromObject(data: {
                conf?: ReturnType<typeof SurveyConf.prototype.toObject>;
                left_ts?: number;
                prop_id?: number;
                min_val?: number;
                max_val?: number;
            }): RespData {
                const message = new RespData({});
                if (data.conf != null) {
                    message.conf = SurveyConf.fromObject(data.conf);
                }
                if (data.left_ts != null) {
                    message.left_ts = data.left_ts;
                }
                if (data.prop_id != null) {
                    message.prop_id = data.prop_id;
                }
                if (data.min_val != null) {
                    message.min_val = data.min_val;
                }
                if (data.max_val != null) {
                    message.max_val = data.max_val;
                }
                return message;
            }
            toObject() {
                const data: {
                    conf?: ReturnType<typeof SurveyConf.prototype.toObject>;
                    left_ts?: number;
                    prop_id?: number;
                    min_val?: number;
                    max_val?: number;
                } = {};
                if (this.conf != null) {
                    data.conf = this.conf.toObject();
                }
                if (this.left_ts != null) {
                    data.left_ts = this.left_ts;
                }
                if (this.prop_id != null) {
                    data.prop_id = this.prop_id;
                }
                if (this.min_val != null) {
                    data.min_val = this.min_val;
                }
                if (this.max_val != null) {
                    data.max_val = this.max_val;
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.has_conf)
                    writer.writeMessage(1, this.conf, () => this.conf.serialize(writer));
                if (this.left_ts != 0) writer.writeInt64(2, this.left_ts);
                if (this.prop_id != 0) writer.writeInt32(3, this.prop_id);
                if (this.min_val != 0) writer.writeInt64(4, this.min_val);
                if (this.max_val != 0) writer.writeInt64(5, this.max_val);
                if (!w) return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RespData {
                const reader =
                        bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                    message = new RespData();
                while (reader.nextField()) {
                    if (reader.isEndGroup()) break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            reader.readMessage(
                                message.conf,
                                () => (message.conf = SurveyConf.deserialize(reader)),
                            );
                            break;
                        case 2:
                            message.left_ts = reader.readInt64();
                            break;
                        case 3:
                            message.prop_id = reader.readInt32();
                            break;
                        case 4:
                            message.min_val = reader.readInt64();
                            break;
                        case 5:
                            message.max_val = reader.readInt64();
                            break;
                        default:
                            reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): RespData {
                return RespData.deserialize(bytes);
            }
        }
    }
    /*
     *  /act/survey/submit
     */
    export class SubmitSurveyReq extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(
            data?:
                | any[]
                | {
                      header?: dependency_1.ActivityApiActivityCommonProto.ReqHeader;
                      act_id?: string;
                      write_time?: number;
                      question_content?: SubmitSurveyReq.QuestionContent[];
                  },
        ) {
            super();
            pb_1.Message.initialize(
                this,
                Array.isArray(data) ? data : [],
                0,
                -1,
                [4],
                this.#one_of_decls,
            );
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
                if ("act_id" in data && data.act_id != undefined) {
                    this.act_id = data.act_id;
                }
                if ("write_time" in data && data.write_time != undefined) {
                    this.write_time = data.write_time;
                }
                if ("question_content" in data && data.question_content != undefined) {
                    this.question_content = data.question_content;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(
                this,
                dependency_1.ActivityApiActivityCommonProto.ReqHeader,
                1,
            ) as dependency_1.ActivityApiActivityCommonProto.ReqHeader;
        }
        set header(value: dependency_1.ActivityApiActivityCommonProto.ReqHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get act_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set act_id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get write_time() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set write_time(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get question_content() {
            return pb_1.Message.getRepeatedWrapperField(
                this,
                SubmitSurveyReq.QuestionContent,
                4,
            ) as SubmitSurveyReq.QuestionContent[];
        }
        set question_content(value: SubmitSurveyReq.QuestionContent[]) {
            pb_1.Message.setRepeatedWrapperField(this, 4, value);
        }
        static fromObject(data: {
            header?: ReturnType<
                typeof dependency_1.ActivityApiActivityCommonProto.ReqHeader.prototype.toObject
            >;
            act_id?: string;
            write_time?: number;
            question_content?: ReturnType<
                typeof SubmitSurveyReq.QuestionContent.prototype.toObject
            >[];
        }): SubmitSurveyReq {
            const message = new SubmitSurveyReq({});
            if (data.header != null) {
                message.header = dependency_1.ActivityApiActivityCommonProto.ReqHeader.fromObject(
                    data.header,
                );
            }
            if (data.act_id != null) {
                message.act_id = data.act_id;
            }
            if (data.write_time != null) {
                message.write_time = data.write_time;
            }
            if (data.question_content != null) {
                message.question_content = data.question_content.map((item) =>
                    SubmitSurveyReq.QuestionContent.fromObject(item),
                );
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<
                    typeof dependency_1.ActivityApiActivityCommonProto.ReqHeader.prototype.toObject
                >;
                act_id?: string;
                write_time?: number;
                question_content?: ReturnType<
                    typeof SubmitSurveyReq.QuestionContent.prototype.toObject
                >[];
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            if (this.act_id != null) {
                data.act_id = this.act_id;
            }
            if (this.write_time != null) {
                data.write_time = this.write_time;
            }
            if (this.question_content != null) {
                data.question_content = this.question_content.map(
                    (item: SubmitSurveyReq.QuestionContent) => item.toObject(),
                );
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (this.act_id.length) writer.writeString(2, this.act_id);
            if (this.write_time != 0) writer.writeInt64(3, this.write_time);
            if (this.question_content.length)
                writer.writeRepeatedMessage(
                    4,
                    this.question_content,
                    (item: SubmitSurveyReq.QuestionContent) => item.serialize(writer),
                );
            if (!w) return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitSurveyReq {
            const reader =
                    bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                message = new SubmitSurveyReq();
            while (reader.nextField()) {
                if (reader.isEndGroup()) break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(
                            message.header,
                            () =>
                                (message.header =
                                    dependency_1.ActivityApiActivityCommonProto.ReqHeader.deserialize(
                                        reader,
                                    )),
                        );
                        break;
                    case 2:
                        message.act_id = reader.readString();
                        break;
                    case 3:
                        message.write_time = reader.readInt64();
                        break;
                    case 4:
                        reader.readMessage(message.question_content, () =>
                            pb_1.Message.addToRepeatedWrapperField(
                                message,
                                4,
                                SubmitSurveyReq.QuestionContent.deserialize(reader),
                                SubmitSurveyReq.QuestionContent,
                            ),
                        );
                        break;
                    default:
                        reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitSurveyReq {
            return SubmitSurveyReq.deserialize(bytes);
        }
    }
    export namespace SubmitSurveyReq {
        export class QuestionContent extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(
                data?:
                    | any[]
                    | {
                          question_id?: number;
                          position?: number[];
                          answer?: string;
                      },
            ) {
                super();
                pb_1.Message.initialize(
                    this,
                    Array.isArray(data) ? data : [],
                    0,
                    -1,
                    [2],
                    this.#one_of_decls,
                );
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("question_id" in data && data.question_id != undefined) {
                        this.question_id = data.question_id;
                    }
                    if ("position" in data && data.position != undefined) {
                        this.position = data.position;
                    }
                    if ("answer" in data && data.answer != undefined) {
                        this.answer = data.answer;
                    }
                }
            }
            get question_id() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set question_id(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get position() {
                return pb_1.Message.getFieldWithDefault(this, 2, []) as number[];
            }
            set position(value: number[]) {
                pb_1.Message.setField(this, 2, value);
            }
            get answer() {
                return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
            }
            set answer(value: string) {
                pb_1.Message.setField(this, 3, value);
            }
            static fromObject(data: {
                question_id?: number;
                position?: number[];
                answer?: string;
            }): QuestionContent {
                const message = new QuestionContent({});
                if (data.question_id != null) {
                    message.question_id = data.question_id;
                }
                if (data.position != null) {
                    message.position = data.position;
                }
                if (data.answer != null) {
                    message.answer = data.answer;
                }
                return message;
            }
            toObject() {
                const data: {
                    question_id?: number;
                    position?: number[];
                    answer?: string;
                } = {};
                if (this.question_id != null) {
                    data.question_id = this.question_id;
                }
                if (this.position != null) {
                    data.position = this.position;
                }
                if (this.answer != null) {
                    data.answer = this.answer;
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.question_id != 0) writer.writeInt64(1, this.question_id);
                if (this.position.length) writer.writePackedInt64(2, this.position);
                if (this.answer.length) writer.writeString(3, this.answer);
                if (!w) return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): QuestionContent {
                const reader =
                        bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                    message = new QuestionContent();
                while (reader.nextField()) {
                    if (reader.isEndGroup()) break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.question_id = reader.readInt64();
                            break;
                        case 2:
                            message.position = reader.readPackedInt64();
                            break;
                        case 3:
                            message.answer = reader.readString();
                            break;
                        default:
                            reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): QuestionContent {
                return QuestionContent.deserialize(bytes);
            }
        }
    }
    export class SubmitSurveyResp extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(
            data?:
                | any[]
                | {
                      header?: dependency_1.ActivityApiActivityCommonProto.RespHeader;
                      data?: SubmitSurveyResp.RespData;
                  },
        ) {
            super();
            pb_1.Message.initialize(
                this,
                Array.isArray(data) ? data : [],
                0,
                -1,
                [],
                this.#one_of_decls,
            );
            if (!Array.isArray(data) && typeof data == "object") {
                if ("header" in data && data.header != undefined) {
                    this.header = data.header;
                }
                if ("data" in data && data.data != undefined) {
                    this.data = data.data;
                }
            }
        }
        get header() {
            return pb_1.Message.getWrapperField(
                this,
                dependency_1.ActivityApiActivityCommonProto.RespHeader,
                1,
            ) as dependency_1.ActivityApiActivityCommonProto.RespHeader;
        }
        set header(value: dependency_1.ActivityApiActivityCommonProto.RespHeader) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_header() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get data() {
            return pb_1.Message.getWrapperField(
                this,
                SubmitSurveyResp.RespData,
                2,
            ) as SubmitSurveyResp.RespData;
        }
        set data(value: SubmitSurveyResp.RespData) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_data() {
            return pb_1.Message.getField(this, 2) != null;
        }
        static fromObject(data: {
            header?: ReturnType<
                typeof dependency_1.ActivityApiActivityCommonProto.RespHeader.prototype.toObject
            >;
            data?: ReturnType<typeof SubmitSurveyResp.RespData.prototype.toObject>;
        }): SubmitSurveyResp {
            const message = new SubmitSurveyResp({});
            if (data.header != null) {
                message.header = dependency_1.ActivityApiActivityCommonProto.RespHeader.fromObject(
                    data.header,
                );
            }
            if (data.data != null) {
                message.data = SubmitSurveyResp.RespData.fromObject(data.data);
            }
            return message;
        }
        toObject() {
            const data: {
                header?: ReturnType<
                    typeof dependency_1.ActivityApiActivityCommonProto.RespHeader.prototype.toObject
                >;
                data?: ReturnType<typeof SubmitSurveyResp.RespData.prototype.toObject>;
            } = {};
            if (this.header != null) {
                data.header = this.header.toObject();
            }
            if (this.data != null) {
                data.data = this.data.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_header)
                writer.writeMessage(1, this.header, () => this.header.serialize(writer));
            if (this.has_data) writer.writeMessage(2, this.data, () => this.data.serialize(writer));
            if (!w) return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubmitSurveyResp {
            const reader =
                    bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                message = new SubmitSurveyResp();
            while (reader.nextField()) {
                if (reader.isEndGroup()) break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(
                            message.header,
                            () =>
                                (message.header =
                                    dependency_1.ActivityApiActivityCommonProto.RespHeader.deserialize(
                                        reader,
                                    )),
                        );
                        break;
                    case 2:
                        reader.readMessage(
                            message.data,
                            () => (message.data = SubmitSurveyResp.RespData.deserialize(reader)),
                        );
                        break;
                    default:
                        reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SubmitSurveyResp {
            return SubmitSurveyResp.deserialize(bytes);
        }
    }
    export namespace SubmitSurveyResp {
        export class BonusData extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(
                data?:
                    | any[]
                    | {
                          prop_id?: number;
                          prop_val?: number;
                      },
            ) {
                super();
                pb_1.Message.initialize(
                    this,
                    Array.isArray(data) ? data : [],
                    0,
                    -1,
                    [],
                    this.#one_of_decls,
                );
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("prop_id" in data && data.prop_id != undefined) {
                        this.prop_id = data.prop_id;
                    }
                    if ("prop_val" in data && data.prop_val != undefined) {
                        this.prop_val = data.prop_val;
                    }
                }
            }
            get prop_id() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set prop_id(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get prop_val() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set prop_val(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data: { prop_id?: number; prop_val?: number }): BonusData {
                const message = new BonusData({});
                if (data.prop_id != null) {
                    message.prop_id = data.prop_id;
                }
                if (data.prop_val != null) {
                    message.prop_val = data.prop_val;
                }
                return message;
            }
            toObject() {
                const data: {
                    prop_id?: number;
                    prop_val?: number;
                } = {};
                if (this.prop_id != null) {
                    data.prop_id = this.prop_id;
                }
                if (this.prop_val != null) {
                    data.prop_val = this.prop_val;
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.prop_id != 0) writer.writeInt64(1, this.prop_id);
                if (this.prop_val != 0) writer.writeInt64(2, this.prop_val);
                if (!w) return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BonusData {
                const reader =
                        bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                    message = new BonusData();
                while (reader.nextField()) {
                    if (reader.isEndGroup()) break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.prop_id = reader.readInt64();
                            break;
                        case 2:
                            message.prop_val = reader.readInt64();
                            break;
                        default:
                            reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): BonusData {
                return BonusData.deserialize(bytes);
            }
        }
        export class RespData extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(
                data?:
                    | any[]
                    | {
                          bonus_data?: SubmitSurveyResp.BonusData;
                      },
            ) {
                super();
                pb_1.Message.initialize(
                    this,
                    Array.isArray(data) ? data : [],
                    0,
                    -1,
                    [],
                    this.#one_of_decls,
                );
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("bonus_data" in data && data.bonus_data != undefined) {
                        this.bonus_data = data.bonus_data;
                    }
                }
            }
            get bonus_data() {
                return pb_1.Message.getWrapperField(
                    this,
                    SubmitSurveyResp.BonusData,
                    1,
                ) as SubmitSurveyResp.BonusData;
            }
            set bonus_data(value: SubmitSurveyResp.BonusData) {
                pb_1.Message.setWrapperField(this, 1, value);
            }
            get has_bonus_data() {
                return pb_1.Message.getField(this, 1) != null;
            }
            static fromObject(data: {
                bonus_data?: ReturnType<typeof SubmitSurveyResp.BonusData.prototype.toObject>;
            }): RespData {
                const message = new RespData({});
                if (data.bonus_data != null) {
                    message.bonus_data = SubmitSurveyResp.BonusData.fromObject(data.bonus_data);
                }
                return message;
            }
            toObject() {
                const data: {
                    bonus_data?: ReturnType<typeof SubmitSurveyResp.BonusData.prototype.toObject>;
                } = {};
                if (this.bonus_data != null) {
                    data.bonus_data = this.bonus_data.toObject();
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.has_bonus_data)
                    writer.writeMessage(1, this.bonus_data, () =>
                        this.bonus_data.serialize(writer),
                    );
                if (!w) return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RespData {
                const reader =
                        bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                    message = new RespData();
                while (reader.nextField()) {
                    if (reader.isEndGroup()) break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            reader.readMessage(
                                message.bonus_data,
                                () =>
                                    (message.bonus_data =
                                        SubmitSurveyResp.BonusData.deserialize(reader)),
                            );
                            break;
                        default:
                            reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): RespData {
                return RespData.deserialize(bytes);
            }
        }
    }
}
