import { ActivityApiSurveyProto as Webview } from '@/proto/ActivityApiSurveyProto'
import { Params, getRequest } from './request'
import { IAnswer } from './answer'
import { Timer } from './questionnaireTimer'
import { closeBrowserPage } from './closeBrowserPage'
import { store } from '@/stores/main'

export function getUrlParams(): Params & {
  url: string
  code: string
  userid: string | number
  token: string
  brand: string
  lang: string
  jsb?: string
} {
  // 使用es5的方式获取url参数
  const url = window.location.href
  const params: any = {}
  const splitSharp = url.split('#')

  splitSharp.forEach((item) => {
    parse(item)
  })

  function parse(path: string) {
    const str = path.split('?')[1]
    if (str) {
      const strs = str.split('&')
      for (let i = 0; i < strs.length; i++) {
        params[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
      }
    }
  }
  const oldParams = store.urlParmas
  const merge = {
    ...oldParams,
    ...params,
  }
  store.urlParmas = merge
  return {
    ...merge,
  }
}

export async function fetchSurvey(act_id: string) {
  const path = '/act/survey/get'
  const { GetSurveyReq, GetSurveyResp } = Webview
  const request = getRequest<typeof GetSurveyReq, typeof GetSurveyResp>(GetSurveyReq, GetSurveyResp)
  return await request(path, {
    act_id,
  })
}

export async function submitSurvey(survey_conf_id: string, question_content: IAnswer[]) {
  const path = '/act/survey/submit'
  const { SubmitSurveyReq, SubmitSurveyResp } = Webview
  const request = getRequest<typeof SubmitSurveyReq, typeof SubmitSurveyResp>(
    SubmitSurveyReq,
    SubmitSurveyResp
  )
  return await request(path, {
    question_content,
    write_time: Timer.getTime(),
    act_id: survey_conf_id,
  })
}

export const close = () => {
  if (window.location.pathname === '/preview') {
    // 预览时使用
    window.parent.postMessage(
      {
        type: 'survey-close',
      },
      '*'
    )
  } else {
    closeBrowserPage()
  }
}

export function enableLog() {
  return true
}

export function randomArray(arr: any[]) {
  return arr.sort(() => Math.random() - 0.5)
}

export function getOptionsExceptOther(options: any) {
  // 提取 other 选项，将其放在最后，并且剩余选项乱序
  const other = options.find((item: any) => item.isOther)
  const rest = options.filter((item: any) => !item.isOther)
  const randomRest = randomArray(rest)
  if (other) {
    return [...randomRest, other]
  }
  return randomRest
}

export function assertNaN(num: any) {
  return Object.prototype.toString.call(num) === '[object Number]' && num !== num
}
