export enum Code {
  // 200301 网络错误
  NETWORK_ERROR = 200301,
  // 200302 问卷不存在
  NOT_EXIST = 200302,
  // 200303 问卷未开始
  NOT_START = 200303,
  // 200304 问卷已过期
  EXPIRED = 200304,
  // 200305 已领取
  RECEIVED = 200305,
  // 200306 请求过于频繁
  TOO_FREQUENT = 200306,
  // 正常
  NORMAL = 0,
}

export interface RespHeader {
  code: Code
  trace_id: string
  msg: string
  timestamp: number
}

export interface RespGetSurvey {
  conf: {
    id: number
    survey_id: number
    effected_at: number
    expired_at: number
    survey: string
    created_at: number
    updated_at: number
  }
  left_ts: number
  min_val?: number
  max_val?: number
}

export enum QuestionType {
  Title = 'title',
  SingleChoice = 'single_select', // 单选题
  MultipleChoice = 'multi_select', // 多选题
  ImageChoice = 'image_select', // 图片选择题
  FillInTheBlank = 'blank', // 填空题
  RATE = 'star', // 评价题
  Trend = 'trend', // 趋势题
  InformationCollection = 'person', // 信息采集题
}

export const Unique = 'unique'

export enum ErrorCode {
  Necessary = 1001,
  Max = 1002,
  Min = 1003,
  MinText,
}

export enum SubmitStatus {
  // 已领取
  RECEIVED = 1,
  // 未领取
  UNRECEIVED = 0,
}

export enum Nation {
  IN = 'in', // 印度
  ID = 'id', // 印尼
  BR = 'br', // 巴西
  VN = 'vn', // 越南
  PK = 'pk', // 巴基斯坦
  BG = 'bg', // 孟加拉
}

// 定义window上的属性
declare global {
  interface Window {
    webkit: any
    abc: any
    jsBridge: any
  }
}
