/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.19.4
 * source: ../../../../../proto/portal/activity-api/activity_common.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace ActivityApiActivityCommonProto {
    export enum GENDER {
        GENDER_UNKNOWN = 0,
        FEMALE = 1,
        MALE = 2,
    }
    /*
    * import "google/api/annotations.proto";

service AccountService {
  rpc GetBalance (GetBalanceReq) returns (GetBalanceResp) {
    option (google.api.http) = {
      post: "/v1/account/balance"
      body: "*"
    };
  }
}
 通用请求头
    */
    export class ReqHeader extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(
            data?:
                | any[]
                | {
                      user_id?: number;
                      auth_token?: string;
                  },
        ) {
            super();
            pb_1.Message.initialize(
                this,
                Array.isArray(data) ? data : [],
                0,
                -1,
                [],
                this.#one_of_decls,
            );
            if (!Array.isArray(data) && typeof data == "object") {
                if ("user_id" in data && data.user_id != undefined) {
                    this.user_id = data.user_id;
                }
                if ("auth_token" in data && data.auth_token != undefined) {
                    this.auth_token = data.auth_token;
                }
            }
        }
        /*
         *  当前登录用户id
         */
        get user_id() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        /*
         *  当前登录用户id
         */
        set user_id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        /*
         *  授权凭证
         */
        get auth_token() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        /*
         *  授权凭证
         */
        set auth_token(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: { user_id?: number; auth_token?: string }): ReqHeader {
            const message = new ReqHeader({});
            if (data.user_id != null) {
                message.user_id = data.user_id;
            }
            if (data.auth_token != null) {
                message.auth_token = data.auth_token;
            }
            return message;
        }
        toObject() {
            const data: {
                user_id?: number;
                auth_token?: string;
            } = {};
            if (this.user_id != null) {
                data.user_id = this.user_id;
            }
            if (this.auth_token != null) {
                data.auth_token = this.auth_token;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.user_id != 0) writer.writeInt64(1, this.user_id);
            if (this.auth_token.length) writer.writeString(2, this.auth_token);
            if (!w) return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ReqHeader {
            const reader =
                    bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                message = new ReqHeader();
            while (reader.nextField()) {
                if (reader.isEndGroup()) break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.user_id = reader.readInt64();
                        break;
                    case 2:
                        message.auth_token = reader.readString();
                        break;
                    default:
                        reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ReqHeader {
            return ReqHeader.deserialize(bytes);
        }
    }
    /*
     *  通用应答头
     */
    export class RespHeader extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(
            data?:
                | any[]
                | {
                      code?: number;
                      trace_id?: string;
                      msg?: string;
                      timestamp?: number;
                  },
        ) {
            super();
            pb_1.Message.initialize(
                this,
                Array.isArray(data) ? data : [],
                0,
                -1,
                [],
                this.#one_of_decls,
            );
            if (!Array.isArray(data) && typeof data == "object") {
                if ("code" in data && data.code != undefined) {
                    this.code = data.code;
                }
                if ("trace_id" in data && data.trace_id != undefined) {
                    this.trace_id = data.trace_id;
                }
                if ("msg" in data && data.msg != undefined) {
                    this.msg = data.msg;
                }
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
            }
        }
        /*
    *  @gotags: json:"code"
 各模块接口定义的业务异常码
    */
        get code() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        /*
    *  @gotags: json:"code"
 各模块接口定义的业务异常码
    */
        set code(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        /*
    *  @gotags: json:"trace_id"
 本次请求唯一事务id，用于问题追踪定位
    */
        get trace_id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        /*
    *  @gotags: json:"trace_id"
 本次请求唯一事务id，用于问题追踪定位
    */
        set trace_id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        /*
    *  @gotags: json:"msg"
 错误信息
    */
        get msg() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        /*
    *  @gotags: json:"msg"
 错误信息
    */
        set msg(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        /*
    *  @gotags: json:"timestamp"
 当前时间截
    */
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        /*
    *  @gotags: json:"timestamp"
 当前时间截
    */
        set timestamp(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            code?: number;
            trace_id?: string;
            msg?: string;
            timestamp?: number;
        }): RespHeader {
            const message = new RespHeader({});
            if (data.code != null) {
                message.code = data.code;
            }
            if (data.trace_id != null) {
                message.trace_id = data.trace_id;
            }
            if (data.msg != null) {
                message.msg = data.msg;
            }
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            return message;
        }
        toObject() {
            const data: {
                code?: number;
                trace_id?: string;
                msg?: string;
                timestamp?: number;
            } = {};
            if (this.code != null) {
                data.code = this.code;
            }
            if (this.trace_id != null) {
                data.trace_id = this.trace_id;
            }
            if (this.msg != null) {
                data.msg = this.msg;
            }
            if (this.timestamp != null) {
                data.timestamp = this.timestamp;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.code != 0) writer.writeInt32(1, this.code);
            if (this.trace_id.length) writer.writeString(2, this.trace_id);
            if (this.msg.length) writer.writeString(3, this.msg);
            if (this.timestamp != 0) writer.writeInt64(4, this.timestamp);
            if (!w) return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RespHeader {
            const reader =
                    bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes),
                message = new RespHeader();
            while (reader.nextField()) {
                if (reader.isEndGroup()) break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.code = reader.readInt32();
                        break;
                    case 2:
                        message.trace_id = reader.readString();
                        break;
                    case 3:
                        message.msg = reader.readString();
                        break;
                    case 4:
                        message.timestamp = reader.readInt64();
                        break;
                    default:
                        reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RespHeader {
            return RespHeader.deserialize(bytes);
        }
    }
}
