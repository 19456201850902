import { computed } from 'vue'
import { createI18n, VERSION } from 'vue-i18n'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { registerItem } from 'virtual-dp-i18n-plugin'
import { getUrlParams } from '@/utils/tools'

export type Language = 'en'
export const defaultLocale = 'en'

export function getBrowserLang() {
  const parmas = getUrlParams()
  let defaultBrowserLang = (parmas.code || parmas.lan || parmas.lang || 'en').toLowerCase()

  if (['cn', 'zh', 'zh-cn'].includes(defaultBrowserLang)) {
    defaultBrowserLang = 'zh-cn'
  }

  if (defaultBrowserLang === 'my') {
    defaultBrowserLang = 'mm'
  }
  return defaultBrowserLang
}

export function getCountry() {
  const parmas = getUrlParams()
  let defaultCountry = (
    parmas.code ||
    parmas.cnt ||
    parmas.lan ||
    parmas.lang ||
    'en'
  ).toLowerCase()
  if (defaultCountry === 'my') {
    defaultCountry = 'mm'
  }
  return defaultCountry
}

const i18n = createI18n({
  // something vue-i18n options here ...
  locale: getBrowserLang(),
  legacy: !/^9\.2/.test(VERSION),
  fallbackLocale: defaultLocale,
  warnHtmlInMessage: 'off',
  messages: {},
})

const LangMap = {
  pt: 'pt',
  hi: 'hi',
  id: 'id',
  en: 'en',
  'zh-cn': 'zh-cn',
  vi: 'vi',
  my: 'mm',
  mm: 'mm',
  pk: 'en',
  th: 'th',
  us: 'us',
}

registerItem((key: string, res: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  i18n.global.mergeLocaleMessage(LangMap[key], res)
}, console.error)

export function t(keyName: string, args: Record<string, any> = {}) {
  const { t } = i18n.global
  return computed(() => {
    return typeof args === 'object' && Object.keys(args).length ? t(keyName, args) : t(keyName)
  })
}

export function tm(keyName: string) {
  const { tm } = i18n.global
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return computed(() => tm(keyName))
}
export default i18n
