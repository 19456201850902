import { isIOS } from 'vant/lib/utils'
import { onMounted, onUnmounted } from 'vue'

export function useOrientation() {
  const orientation = {
    get angle() {
      if (typeof window.orientation === 'number') {
        return window.orientation
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const orientation = screen.orientation || screen.msOrientation || screen.mozOrientation
      if (orientation.angle === 0) return 0

      if (isIOS()) {
        if (orientation.type === 'landscape-secondary' && orientation.angle === 270) return 90
        if (orientation.type === 'landscape-primary' && orientation.angle === 90) return -90
        return orientation.angle
      }

      if (orientation.type === 'landscape-secondary' && orientation.angle === 270) return -90
      if (orientation.type === 'landscape-primary' && orientation.angle === 90) return 90

      return 0
    },
  }

  function handleScreenOrientation() {
    handleAppBoxSize()
    handleBodyRotate()
  }

  function handleAppBoxSize() {
    const rotate = orientation.angle !== 0
    const appEl = document.getElementById('app')
    if (!appEl) return

    if (rotate) {
      appEl.style.width = '100vh'
      appEl.style.height = '100vw'
      return
    }

    // 非旋转清空样式
    appEl.style.width = ''
    appEl.style.height = ''
  }

  function handleBodyRotate() {
    if (orientation.angle === 0) {
      document.body.id = ''
      return
    }

    if (orientation.angle === 90) {
      document.body.id = 'rotate90'
      return
    }

    if (orientation.angle === -90) {
      document.body.id = 'rotate_z90'
      return
    }
  }

  onMounted(() => {
    handleScreenOrientation()

    window.addEventListener('orientationchange', handleScreenOrientation)
  })

  onUnmounted(() => {
    window.removeEventListener('orientationchange', handleScreenOrientation)
  })
}
