// import { MemoryStorage } from '@/utils/MemoryStorage'
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/:id',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/DHome.vue'),
  },
  {
    // NORMAL的时候
    path: '/end',
    name: 'End',
    component: () => import(/* webpackChunkName: "end" */ '../views/DEnd.vue'),
  },
  {
    path: '/expired',
    name: 'expired',
    component: () => import(/* webpackChunkName: "end" */ '../views/DEnd.vue'),
  },
  {
    path: '/received',
    name: 'received',
    component: () => import(/* webpackChunkName: "end" */ '../views/DEnd.vue'),
  },
  {
    path: '/tooFrequent',
    name: 'tooFrequent',
    component: () => import(/* webpackChunkName: "end" */ '../views/DEnd.vue'),
  },
  {
    path: '/notExist',
    name: 'notExist',
    component: () => import(/* webpackChunkName: "end" */ '../views/DEnd.vue'),
  },
  {
    path: '/notStart',
    name: 'notStart',
    component: () => import(/* webpackChunkName: "end" */ '../views/DEnd.vue'),
  },
  {
    path: '/networkError',
    name: 'networkError',
    component: () => import(/* webpackChunkName: "end" */ '../views/DEnd.vue'),
  },
  // error
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/DEnd.vue'),
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import(/* webpackChunkName: "about" */ '../views/DHome.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
