import Client from '@dp/telegram'
import * as Pb from 'google-protobuf'
import { enableLog, getUrlParams } from './tools'

type createParamsType = Parameters<typeof Client.create>[0]
export type Params = createParamsType['params']

type Message = typeof Pb.Message & {
  fromObject: (arg: any) => any
}

export type ExtractFromObjectArg<T> = T extends { fromObject: (arg: infer U) => any } ? U : never

export function getRequest<T extends Message, K extends Message>(Req: T, Res: K) {
  const fullParams = getUrlParams()
  const params = {
    auth_token: fullParams.token,
    user_id: Number(fullParams.userid),
    brd: fullParams.brd || fullParams.brand,
    aid: fullParams.aid,
    svn: fullParams.svn,
    svc: fullParams.svc,
    cvc: fullParams.cvc,
    chn: fullParams.chn,
    pkg: fullParams.pkg,
    app: fullParams.app,
    mch: fullParams.mch,
    bvs: fullParams.bvs,
    lan: fullParams.lan || fullParams.lang,
    cnt: fullParams.cnt,
    cvn: fullParams.cvn,
  } as Params
  const request = Client.create({
    secret: 'SEWkGIc2RiqcASstuR4kuw==',
    params,
  })

  if (enableLog()) {
    console.log('params', params)
  }

  return (path: string, data: ExtractFromObjectArg<T>) => {
    const url = checkUrl(fullParams.url)

    if (enableLog()) {
      console.log('request', url + path, data)
    }
    return request({
      url: `${url}${path}`,
      data,
      Req,
      Res,
    })
  }
}

function checkUrl(url: string) {
  if (!url) {
    throw new Error('url is required')
  }
  if (!url.startsWith('http')) {
    throw new Error('url must start with http')
  }
  // 判断结尾是否有 /，有的话就去掉
  if (url.endsWith('/')) {
    return url.slice(0, -1)
  }
  return url
}
