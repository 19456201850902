
  function runner(url, success, err) {
    let _url = url
    try {
      _url = new URL(url).href
    } catch (error) {
      console.error(error)
    }
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.open('GET', _url)
      xhr.onload = () => {
        if (xhr.status === 200) {
          success(JSON.parse(xhr.responseText))
        } else {
          err(xhr.statusText)
        }
      }
      xhr.send()
    })
  }

  let baseUrl = window.location.origin;

  let publicPath = '';

  if( document.currentScript &&  document.currentScript.src && document.currentScript.src.match(/(https|http):\/\/.*?\//)){
      const scriptUrl = document.currentScript.src.match(/(https|http):\/\/.*?\//) || []
      if (scriptUrl.length){
        baseUrl = scriptUrl[0].substring(0, scriptUrl[0].length - 1)
      }
  }

  while(publicPath.endsWith('/')){
    publicPath = publicPath.substring(0, publicPath.length - 1)
  }


  while(publicPath.startsWith('/')){
    publicPath = publicPath.substring(1)
  }

  publicPath = publicPath || '.'

  export function registerItem(successHandle, errorHandle) {
    runner(baseUrl + '/' + publicPath + '/i18n-en.json?version=1709532594143', (res) => successHandle('en', res), errorHandle);runner(baseUrl + '/' + publicPath + '/i18n-hi.json?version=1709532594143', (res) => successHandle('hi', res), errorHandle);runner(baseUrl + '/' + publicPath + '/i18n-id.json?version=1709532594143', (res) => successHandle('id', res), errorHandle);runner(baseUrl + '/' + publicPath + '/i18n-my.json?version=1709532594143', (res) => successHandle('my', res), errorHandle);runner(baseUrl + '/' + publicPath + '/i18n-pt.json?version=1709532594143', (res) => successHandle('pt', res), errorHandle);runner(baseUrl + '/' + publicPath + '/i18n-th.json?version=1709532594143', (res) => successHandle('th', res), errorHandle);runner(baseUrl + '/' + publicPath + '/i18n-vi.json?version=1709532594143', (res) => successHandle('vi', res), errorHandle);runner(baseUrl + '/' + publicPath + '/i18n-zh-cn.json?version=1709532594143', (res) => successHandle('zh-cn', res), errorHandle);
  }

  function registerAll() {
    return new Promise((relove, reject) => {
      runner(baseUrl + '/' + publicPath + '/i18n.json?version=1709532594143', relove, reject)
    })
  }

  export default registerAll
  