import { getUrlParams } from './tools'

function openInWebview(): any {
  const ua = navigator.userAgent
  const iOS = !!ua.match(/(iPhone|iPad)/i)
  const android = ua.match(/Android/i)
  if (iOS || android) {
    return true
  } else {
    return false
  }
}
/** 跳转游戏大厅 */
export function closeBrowserPage() {
  if (window.parent !== window) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.parent!.postMessage({ type: 'exit_customer_page' }, '*')
    return
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ibc) {
    window.webkit.messageHandlers.ibc.postMessage({ action: 'quit' }, '*')
  } else if (window.abc) {
    window.abc.quitBrowser()
  } else {
    if (openInWebview()) {
      window.parent.postMessage({ type: 'exit_customer_page', data: true }, '*')
      //ios发送关闭方法
      window.webkit?.messageHandlers?.jsBridge?.postMessage({ selector: 'close' })
      window.jsBridge ? window.jsBridge?.close?.() : window.close()
      const { jsb } = getUrlParams()
      if (jsb) {
        const key = jsb.split('.')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.[key[0]]?.[key[1]]?.('{"methodName":"close"}')
      }
    } else {
      // 其他地方
      window.close()
    }
  }
}
