import { MemoryStorage } from '@/utils/MemoryStorage'
import { assertNaN } from './tools'
const storage = MemoryStorage.getInstance()

class QuestionnaireTimer {
  time = 0
  duration = 333
  timer: any
  init = false
  constructor() {
    this.time = 0
  }

  initTime(key: string) {
    if (this.init) {
      return
    }
    this.init = true
    const time = Number(storage.getItem(key))
    if (assertNaN(time)) {
      this.time = 0
    } else {
      this.time = time
    }
    this.saveTime(key)
    window.addEventListener('beforeunload', () => {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    })
  }

  setTime(key: string) {
    this.time = this.time + this.duration
    storage.setItem(key, String(this.time))
  }

  clearTime(key: string) {
    this.time = 0
    clearTimeout(this.timer)
    storage.removeItem(key)
  }

  // 定时保存
  saveTime(key: string) {
    this.timer = setTimeout(() => {
      this.setTime(key)
      this.saveTime(key)
    }, this.duration)
  }

  getTime() {
    // 精确到秒
    return Math.floor(this.time / 1000)
  }

  // 单例
  static instance: QuestionnaireTimer | null = null

  static getInstance() {
    if (!QuestionnaireTimer.instance) {
      QuestionnaireTimer.instance = new QuestionnaireTimer()
    }
    return QuestionnaireTimer.instance
  }
}

export const Timer = QuestionnaireTimer.getInstance()
